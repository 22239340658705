import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  const endpoint = location.pathname.slice(1);
  // console.log(endpoint)
  return (
    <footer className="main-footer">
      {/* tree and deer */}
      {(endpoint === "" ||
        endpoint === "agnitor/" ||
        endpoint === "privacy-policy/" ||
        endpoint === "agnitor" ||
        endpoint === "vaves/" ||
        endpoint === "vaves" ||
        endpoint === "org/" ||
        endpoint === "org" ||
        endpoint === "news/" ||
        endpoint === "news" ||
        endpoint === "news/events/" ||
        endpoint === "news/events" ||
        endpoint === "right-apps/" ||
        endpoint === "right-apps" ||
        endpoint.includes("customers") ||
        endpoint.includes("careers") ||
        endpoint === "vaves" ||
        endpoint === "vaves/" ||
        endpoint === "virtuosoship/" ||
        endpoint === "virtuosoship" ||
        endpoint === "vuca/covid/" ||
        endpoint === "vuca/covid" ||
        endpoint === "blog" ||
        endpoint === "blog/:id" ||
        endpoint === "invincible" ||
        endpoint === "invincible/" ||
        endpoint === "succeedant" ||
        endpoint === "succeedant/" ||
        endpoint === "insights" ||
        endpoint === "insights/" ||
        endpoint === "investors" ||
        endpoint === "investors/" ||
        endpoint === "contact/" ||
        endpoint === "contact" ||
        endpoint === "business4-0/rigour" ||
        endpoint === "business4-0/rigour/" ||
        endpoint === "nexorama/" ||
        endpoint === "nexorama" ||
        endpoint === "experience-cloud/aury" ||
        endpoint === "experience-cloud/aury/" ||
        endpoint === "asana" ||
        endpoint === "bpm-cx" ||
        endpoint === "bpm-cx/" ||
        endpoint === "oracle/oracle-service-cloud/" ||
        endpoint === "oracle/oracle-service-cloud" ||
        endpoint === "o-digital/digital-asap/" ||
        endpoint === "o-digital/digital-asap/" ||
        endpoint === "o-digital/cx-continuum" ||
        endpoint === "o-digital/cx-continuum/" ||
        endpoint.includes("alliances") ||
        endpoint.includes("corporate") ||
        endpoint.includes("community") ||
        endpoint.includes("covid") ||
        endpoint.includes("vlocis") ||
        endpoint.includes("thankyou") ||
        endpoint.includes("terms-of-use") ||
        endpoint.includes("news") ||
        endpoint.includes("announcing-4.0-as-a-strategy") ||
        endpoint.includes("coronavirus-impact-on-business") ||
        endpoint.includes("customer-experience-meet-2012") ||
        endpoint.includes("gartner-global-marketing-summit-2015") ||
        endpoint.includes("giftcart.com-launches-its-beta-commerce-site") ||
        endpoint.includes("maximizer-virtuos-announces-strong-partnership") ||
        endpoint.includes("nexorama-strategy") ||
        endpoint.includes("oracle-acquires-rightnow-technologies") ||
        endpoint.includes(
          "oracle-welcomes-virtuos-into-the-oracle-partner-network"
        ) ||
        endpoint.includes("rightnow-technologies-honours-virtuos") ||
        endpoint.includes("unleashing-business-4-strategy") ||
        endpoint.includes(
          "venky-vijay-speaks-to-the-press-at-oracle-cloud-event"
        ) ||
        endpoint.includes("verint-acquires-kana-software") ||
        endpoint.includes("virtuos-acquires-enforce.com") ||
        endpoint.includes(
          "virtuos-announces-post-covid-businesses-xonomy-and-brandexpo"
        ) ||
        endpoint.includes(
          "virtuos-attends-bpmonline-global-partner-summit-accelerate-2018"
        ) ||
        endpoint.includes("virtuos-attends-dreamforce-2019") ||
        endpoint.includes(
          "virtuos-attends-dreamforce-kana-connect-oracle-open-world-2012"
        ) ||
        endpoint.includes(
          "virtuos-attends-gartner-summit-for-marketers-and-forrester-2016"
        ) ||
        endpoint.includes("virtuos-attends-kana-customer-summit-in-uk") ||
        endpoint.includes(
          "virtuos-attends-rightnow-user-conference-in-melbourne-australia"
        ) ||
        endpoint.includes(
          "virtuos-awarded-as-cx-partner-of-the-year-by-oracle"
        ) ||
        endpoint.includes("virtuos-bags-asia-biggest-ondemand-crm") ||
        endpoint.includes("virtuos-bags-crm-contract-from-leading-bpo") ||
        endpoint.includes(
          "virtuos-bags-crm-service-cloud-contract-from-flipkart"
        ) ||
        endpoint.includes("virtuos-bags-orders-for-kana-and-maximizer") ||
        endpoint.includes(
          "virtuos-becomes-premium-partner-rightnow-technologies"
        ) ||
        endpoint.includes("virtuos-celebrates-its-10th-anniversary") ||
        endpoint.includes("virtuos-celebrates-its-11th-anniversary") ||
        endpoint.includes(
          "virtuos-ceo-attends-dreamforce-salesforce-global-event-2014"
        ) ||
        endpoint.includes("virtuos-clicksoftware-sign-partnership") ||
        endpoint.includes("virtuos-cloudle-oracle-netsuite-form-alliance") ||
        endpoint.includes(
          "virtuos-co-sponsors-oracle-applications-apac-partners-meet"
        ) ||
        endpoint.includes("virtuos-co-sponsors-oracle-cloud-world-2014") ||
        endpoint.includes("virtuos-co-sponsors-oracle-cloud-world-in-mumbai") ||
        endpoint.includes("virtuos-co-sponsors-top-indian-banks-2008") ||
        endpoint.includes("virtuos-completes-a-wonderful-7-years") ||
        endpoint.includes(
          "virtuos-consultare-and-oracle-consulting-services-sign-mou"
        ) ||
        endpoint.includes(
          "virtuos-consultare-implements-oracle-rightnow-cx-in-china"
        ) ||
        endpoint.includes("virtuos-creates-new-blueprint-for-fy20-growth") ||
        endpoint.includes("virtuos-embraces-business-n-model") ||
        endpoint.includes("virtuos-enveils-a-major-rebranding") ||
        endpoint.includes(
          "virtuos-xonomy-invests-in-experience-economy-company"
        ) ||
        endpoint.includes(
          "virtuos-holds-its-first-experience-executive-summit"
        ) ||
        endpoint.includes(
          "virtuos-implements-clicksoftware-field-service-at-barc-and-hicare"
        ) ||
        endpoint.includes(
          "virtuos-implements-kana-solution-at-standard-chartered-bank"
        ) ||
        endpoint.includes(
          "virtuos-implements-oracle-cx-at-tvs-credit-craftsvilla-india-first"
        ) ||
        endpoint.includes("virtuos-implements-salesforce-at-paytm") ||
        endpoint.includes("virtuos-incorporated-in-hyderabad") ||
        endpoint.includes("virtuos-india-closes-financial-year-2012") ||
        endpoint.includes("virtuos-joins-sap-partneredge-program") ||
        endpoint.includes("virtuos-launches-co-extend-delivery-methodology") ||
        endpoint.includes("virtuos-launches-consultare-division") ||
        endpoint.includes("virtuos-launches-innovation-open") ||
        endpoint.includes("virtuos-launches-new-division-yippee-media") ||
        endpoint.includes("virtuos-launches-new-edition-of-website") ||
        endpoint.includes("virtuos-leadership-meets-maximizer-team-in-uk") ||
        endpoint.includes("virtuos-moves-to-a-new-office-2013") ||
        endpoint.includes("virtuos-moves-to-a-new-office-in-gurgaon") ||
        endpoint.includes("virtuos-moves-to-new-office-emaar-digital-greens") ||
        endpoint.includes("virtuos-opens-new-office-in-mumbai") ||
        endpoint.includes("virtuos-oracle-cx-tour-in-delhi-mumbai") ||
        endpoint.includes("virtuos-participates-in-dreamforce-2015") ||
        endpoint.includes(
          "virtuos-presents-business-through-social-at-oracle-social-crm-events"
        ) ||
        endpoint.includes(
          "virtuos-signs-partnership-with-rightnow-technologies"
        ) ||
        endpoint.includes(
          "virtuos-signs-two-new-deals-party-gaming-and-cashtech"
        ) ||
        endpoint.includes("virtuos-signs-up-with-gartner-india") ||
        endpoint.includes("virtuos-signs-up-with-maximizer") ||
        endpoint.includes("virtuos-snaps-thomas-cook-india") ||
        endpoint.includes(
          "virtuos-solutions-changes-its-name-to-virtuos-digital"
        ) ||
        endpoint.includes("virtuos-solutions-moves-to-a-new-office") ||
        endpoint.includes(
          "virtuos-team-attends-cx-io-gartner-marketing-summit"
        ) ||
        endpoint.includes("virtuos-team-attends-dreamforce-2013") ||
        endpoint.includes("virtuos-team-attends-dreamforce-2017") ||
        endpoint.includes(
          "virtuos-team-attends-gartner-application-strategies-solutions-summit-2018"
        ) ||
        endpoint.includes(
          "virtuos-team-attends-gartner-digital-marketing-symposium-2019"
        ) ||
        endpoint.includes(
          "virtuos-team-attends-oracle-apac-partner-summit-2015"
        ) ||
        endpoint.includes(
          "virtuos-team-attends-oracle-open-world-at-moscone-center-san-francisco"
        ) ||
        endpoint.includes(
          "virtuos-team-attends-rightnow-apac-summit-in-austraila"
        ) ||
        endpoint.includes("virtuos-team-attends-salesforce-conference-2016") ||
        endpoint.includes("virtuos-teams-undergo-maximizer-crm-10-training") ||
        endpoint.includes(
          "virtuos-to-enter-in-ecommerce-sector-with-giftcart.com"
        ) ||
        endpoint.includes(
          "virtuos-to-focus-on-global-expansion-in-new-fiscal-year"
        ) ||
        endpoint.includes("virtuos-to-implement-maximizer-crm") ||
        endpoint.includes(
          "virtuos-to-unveil-its-first-product-strategy-aury"
        ) ||
        endpoint.includes("virtuos-to-unveil-NOVV") ||
        endpoint.includes("virtuos-veracis-launches-cxdesk-expedience") ||
        endpoint.includes(
          "virtuos-verint-sign-partnership-to-bring-cx-ecosystems"
        ) ||
        endpoint.includes("virtuos-vivacis-global-projects") ||
        endpoint.includes("virtuos-vivacis-implements-CLM") ||
        endpoint.includes("virtuos-vivacis-oracle-netsuite-form-alliance") ||
        endpoint.includes("virtuos-vlocis-implements-CLM") ||
        endpoint.includes("dpa") ||
        endpoint.includes("survey") ||
        endpoint.includes("succeedant") ||
        endpoint.includes("safe-harbour") ||
        endpoint.includes("asana") ||
        endpoint.includes("webinars") ||
        endpoint.includes("events") ||
        endpoint.includes("virtuos-cx-summit-2016") ||
        endpoint.includes("customers/thomascook-case-study") ||
        endpoint.includes("digital/digital-asap/") ||
        endpoint.includes("smartsheet") ||
        endpoint.includes("coming-soon") ||
        endpoint.includes("ai-ambition") ||
        endpoint.includes("crm-studio") ||
        endpoint.includes("coming-soon")) && (
          <>
            <div className="lft-bg">
              <img
                src={`/img/svg/company-footer-lft-bg.svg`}
                alt="Footer Left Background Image"
              />
            </div>
            <div className="rgt-bg">
              <img
                src={`/img/svg/company-footer-rgt-bg.svg`}
                alt="Footer Right Background Image"
              />
            </div>
          </>
        )}

      {/* bulb and ladder */}
      {(endpoint === "xonomy/aury/" ||
        endpoint.includes("rainbowcx") ||
        endpoint === "xonomy/" ||
        endpoint === "xonomy" ||
        endpoint === "experience-cloud/" ||
        endpoint === "experience-cloud" ||
        endpoint === "experience-crowd/" ||
        endpoint === "xonomy/aury/" ||
        endpoint === "xonomy/budh/" ||
        endpoint === "xonomy/ai/self-service/" ||
        endpoint === "experience-cloud/" ||
        endpoint === "experience-cloud" ||
        endpoint === "_salesforce/" ||
        endpoint === "_salesforce" ||
        endpoint === "io/design-thinking/" ||
        endpoint === "io/design-thinking" ||
        endpoint === "ventures/" ||
        endpoint === "/experience-cloud/") && (
          <>
            <div class="lft-bg">
              <img src={`/img/svg/exonomy-footer-lft-bg.svg`} alt="" />
            </div>
            <div class="rgt-bg">
              <img src={`/img/svg/exonomy-footer-rgt-bg.svg`} alt="" />
            </div>
          </>
        )}

      {/* building and people */}
      {(endpoint === "vivacis/" ||
        endpoint === "business/" ||
        endpoint === "indicial/" ||
        endpoint === "all-agile/" ||
        endpoint === "cx" ||
        endpoint === "experienceo/" ||
        endpoint === "ex/strategy-design/" ||
        endpoint === "ex" ||
        endpoint === "ex/" ||
        endpoint === "cloud/" ||
        endpoint === "cloud" ||
        endpoint === "ex/strategy-design/" ||
        endpoint === "ex/strategy-design" ||
        endpoint === "crayonz" ||
        endpoint === "crayonz/" ||
        endpoint.includes("agilenterprise") ||
        endpoint.includes("agilenterprise/") ||
        endpoint === "agiloft" ||
        endpoint === "agiloft/" ||
        endpoint === "digital" ||
        endpoint === "digital/" ||
        endpoint === "cx/strategy-design/" ||
        endpoint === "cx/strategy-design" ||
        endpoint === "cx/cx-marries-digital" ||
        endpoint === "cx/cx-marries-digital/" ||
        endpoint === "branding" ||
        endpoint === "branding/" ||
        endpoint === "o-digital/" ||
        endpoint === "o-digital" ||
        endpoint === "qualtrics/" ||
        endpoint === "qualtrics" ||
        endpoint === "industry/" ||
        endpoint === "industry" ||
        endpoint === "creatio/" ||
        endpoint === "creatio" ||
        endpoint === "crayonz/" ||
        endpoint === "crayonz" ||
        endpoint.includes("cxdesk") ||
        endpoint.includes("cxnow") ||
        endpoint === "experience-new" ||
        endpoint === "consultare/engineering-services" ||
        endpoint === "consultare/engineering-services/" ||
        endpoint === "experience-new/" ||
        endpoint === "business" ||
        endpoint === "io" ||
        endpoint === "io/" ||
        endpoint === "io/design-thinking" ||
        endpoint === "io/design-thinking/" ||
        endpoint === "io/lab" ||
        endpoint === "io/lab/" ||
        endpoint === "io/virtuos" ||
        endpoint === "io/virtuos/" ||
        endpoint.includes("consultare") ||
        endpoint.includes("solutions") ||
        endpoint.includes("c-digital") ||
        endpoint.includes("crm-digital") ||
        endpoint.includes("w-digital") ||
        endpoint.includes("talentare") ||
        endpoint.includes("oracle")) && (
          <>
            <div className="lft-bg">
              <img src={`/img/svg/consultare-footer-lft-bg.svg`} alt="" />
            </div>
            <div className="rgt-bg">
              <img src={`/img/svg/consultare-footer-rgt-bg.svg`} alt="" />
            </div>
          </>
        )}

      <section className="row page-wrap quick-links">
        <div className="col-12 row">
          <div
            className="col-2-half wow fadeInLeft duration animated"
            data-wow-duration="1000ms"
            data-wow-delay="700ms"
          >
            <h2>
              <NavLink className="footer-navlinks" to={`/consultare/`}>
                Consultare
              </NavLink>
            </h2>
            <ul className="sub-menu">
              <li>
                <Link to={`/crm-digital/`}>CRM Digital</Link>
              </li>
              <li>
                <Link to={`/cx/`}>Customer Experience</Link>
              </li>
              <li>
                <Link to={`/ex/`}>Employee Experience</Link>
              </li>
              <li>
                <Link to={`/xx-digital/`}>Everything Experience</Link>
              </li>
              <li>
                <Link to={`/alliances/`}>Key Partners</Link>
              </li>
            </ul>
          </div>
          <div
            className="col-2-half wow fadeInDown duration animated"
            data-wow-duration="1000ms"
            data-wow-delay="700ms"
          >
            <h2>
              <NavLink className="footer-navlinks" to={`/exonomy`}>
                Xonomy
              </NavLink>
            </h2>
            <ul className="sub-menu">
              <li>
                <Link to="http://www.giftcart.com" target="_blank">
                  Experience Commerce Business
                </Link>
              </li>
              <li>
                <Link to="http://www.indic.com" target="_blank">
                  Digital Experience Business
                </Link>
              </li>
              <li>
                <Link to={`/salesforce/`}>Salesforce Xonomy</Link>
              </li>
              <li>
                <Link to={`/io/`}>Innovation On Lab</Link>
              </li>
              <li>
                <Link to={`/nexorama/`}>Virtuos Nexorama</Link>
              </li>
            </ul>
          </div>
          <div
            className="col-2-half wow fadeInLeft duration animated"
            data-wow-duration="1000ms"
            data-wow-delay="700ms"
          >
            <h2>
              <NavLink className="footer-navlinks" to={`/business/`}>
                The Business<sup style={{ fontSize: "10px" }}>(9)</sup>
              </NavLink>
            </h2>
            <ul className="sub-menu">
              <li>
                <Link to={`/business/`}>
                  Business<sup style={{ fontSize: "10px" }}>(9)</sup> by Needs
                </Link>
              </li>
              <li>
                <Link to={`/solutions/`}>Solutions by Function</Link>
              </li>
              <li>
                <Link to={`/industry/`}>Industries</Link>
              </li>
              <li>
                <Link to={`/business4-0/`}>
                  Innovation Insight &amp; Strategy
                </Link>
              </li>
              <li>
                <Link to={`/alliances/`}>Partner Solutions</Link>
              </li>
            </ul>
          </div>
          <div
            className="col-2-half wow fadeInDown duration animated"
            data-wow-duration="1000ms"
            data-wow-delay="700ms"
          >
            <h2>
              <NavLink className="footer-navlinks" to={`/corporate/`}>
                The Company
              </NavLink>
            </h2>
            <ul className="sub-menu">
              <li>
                <Link to={`/corporate/`}>About Virtuos</Link>
              </li>
              <li>
                <Link to={`/business/`}>Our Business</Link>
              </li>
              <li>
                <Link to={`/customers/`}>Customer Success</Link>
              </li>
              <li>
                <Link to={`/careers/`}>Careers</Link>
              </li>
              <li>
                <Link to={`/news/`}>News &amp; Events</Link>
              </li>
            </ul>
          </div>
          <div
            className="col-2 wow fadeInRight duration animated"
            data-wow-duration="1000ms"
            data-wow-delay="700ms"
          >
            <h2>
              <NavLink className="footer-navlinks" to="javascript:void(0);">
                FOLLOW US
              </NavLink>
            </h2>
            <ul className="bottom-social-wrapper">
              <li>
                <NavLink
                  to="https://www.facebook.com/Virtuos/"
                  className="fab fa-facebook-f"
                  rel="noreferrer"
                  target="_blank"
                ></NavLink>
              </li>
              <li>
                <NavLink
                  to="https://twitter.com/virtuos"
                  className="fab fa-twitter"
                  rel="noreferrer"
                  target="_blank"
                ></NavLink>
              </li>
              <li>
                <NavLink
                  to="https://www.linkedin.com/company/virtuos/"
                  className="fab fa-linkedin-in"
                  rel="noreferrer"
                  target="_blank"
                ></NavLink>
              </li>
              <li>
                <NavLink
                  to="http://pinterest.com/virtuoscorp"
                  className="fab fa-pinterest-p"
                  rel="noreferrer"
                  target="_blank"
                ></NavLink>
              </li>
              <li>
                <NavLink
                  to="https://www.youtube.com/user/virtuoscorp"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </NavLink>
              </li>
            </ul>

            <h2>INDUSTRY MEMBERSHIPS</h2>

            <div className="members">
              <figure>
                <Link to="http://www.ama.org" target="_blank" rel="noreferrer">
                  <img
                    src={`/img/american-marketing-association-logo.png`}
                    alt="American Marketing Association"
                  />
                </Link>
              </figure>
              <figure>
                <Link to="http://www.cii.in" target="_blank" rel="noreferrer">
                  <img src={`/img/svg/icons/cii-logo.svg`} alt="CII" />
                </Link>
              </figure>
              <figure>
                <Link
                  to="http://www.nasscom.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://www.virtuos.com//img/svg/icons/nasscom-logo.svg"
                    alt="Nasscom"
                  />
                </Link>
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="page-wrap row footer-bottom">
        <div className="left-content">
          <h2>VIRTUOS. CUSTOMER @ HEART</h2>
          <p>© 2008~2025 Virtuos Corporation. All rights reserved.
          </p>
        </div>
        <div className="right-bottom-footer">
          <ul className="row legal">
            <li>
              <NavLink to={`/safe-harbour/`}>Safe Harbour</NavLink>
            </li>
            <li>
              <NavLink to={`/contact/`}>Contact</NavLink>
            </li>
            <li>
              <NavLink to={`/terms-of-use/`}>Terms of use</NavLink>
            </li>
            <li>
              <NavLink to={`/privacy-policy/`}>Privacy Policy</NavLink>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
